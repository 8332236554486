
              @import "@/assets/css/variables.scss";
            





























































































































.change_password {
  max-width: 392px;
  margin: 0 auto;

  .btn-item {
    text-align: center;
  }
}
